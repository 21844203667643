//main.js --- 
// 
// Filename     : main.js
// Description  : Main script that handle the on page load.
// Author       : Christophe Burki
// Maintainer   : Christophe Burki
// Created      : Tue Feb 24 13:21:36 2015
// Version      : 1.0.0
// Last-Updated : Wed Mar 20 18:12:54 2019 (3600 CET)
//           By : Jose Ramon del Rio
//     Update # : 529
// URL          : 
// Keywords     : 
// Compatibility: 
// 
//

//Commentary   : 
// 
// 
// 
//

//Change log:
// 
// 
//
// 
// This program is free software; you can redistribute it and/or modify
// it under the terms of the GNU General Public License as published by
// the Free Software Foundation; either version 2, or (at your option)
// any later version.
// 
// This program is distributed in the hope that it will be useful,
// but WITHOUT ANY WARRANTY; without even the implied warranty of
// MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
// GNU General Public License for more details.
// 
// You should have received a copy of the GNU General Public License
// along with this program; see the file COPYING.  If not, write to the
// Free Software Foundation, Inc., 51 Franklin Street, Fifth
// ;; Floor, Boston, MA 02110-1301, USA.
// 
//

//Code         :

/* -------------------------------------------------------------------------- */

var theUpdater = null;
var requestsQueue = null;
var messageOverlay = null;

/* -------------------------------------------------------------------------- */

jQuery(document).ready(function() { onPageReady(jq4fg); });
jQuery(window).unload(onPageUnload);
jQuery(window).bind('hashchange',
                    onHashChanged);

/* -------------------------------------------------------------------------- */

function onPageReady($) {
    /**
     * Called when the page is ready.
     */

    //console.log("onPageReady()");
    
    requestsQueue = new request.RequestsQueue('app-requests-queue',
                                              fgServerUrl);
    theUpdater = new updater.Updater('app-updater',
                                     1000);
    var appView = new viewContext.ViewContext('app-view');

    var body = $('#body');
    var site = body.data('app-site');
    appSettings.design = body.data('app-design');
    appSettings.site = appSettings.design;
    if (site != undefined) {
        appSettings.site = site;
    }
    var appWidth = body.data('app-width');
    var queryString = body.data('querystring');
    var sid = body.data('sid');

    /* Check if we have a SID. */
    if (sid != undefined) {
        appState.setSid(sid);
    }
    
    /* Check the query string. */
    if ((queryString == undefined)
        || (queryString === null)) {

        queryString = '';
    }

    /* Extracts the arguments from the query string and build a map. */
    var argsMap = {};
    var args = queryString.split('&');
    for (var i = 0; i < args.length; i++) {
        var tmp = args[i].split('=');
        if (tmp.length == 2) {
            argsMap[tmp[0]] = decodeURIComponent(tmp[1]);
        }
    }
    
    /* Extracts the arguments from the hash and build a map. */
    var hashArgsMap = {};
    if (window.location.hash.length != 0) {
        var tmp = window.location.hash.replace(/^#.*\?/, '');
        var hashArgs = tmp.split('&');
        for (var i = 0; i < hashArgs.length; i++) {
            var tmp = hashArgs[i].split('=');
            if (tmp.length == 2) {
                hashArgsMap[tmp[0]] = decodeURIComponent(tmp[1]);
            }
        }
    }

    //console.log("queryString=" + queryString);
    //console.log(argsMap);
    //console.log("hash=" + window.location.hash);
    //console.log(hashArgsMap);

    /* Set the application and path. */
    appSettings.app = 'fingraphs';
    appSettings.appPath = 'FinGraphs3';
    
    /* Add handlers for media queries changes and trigger them immediately. */
    /* WARNING : Uncomment the following block for mobile support */
//    console.log("??????????????? FinGraphs3/main");
    mediaQueries.addEventListener('desktop',
                                  'match',
                                  function(media, event) {
//                                      console.log("??????????????? " + media + " ????????????????");
                                      body.width(appWidth);
                                  });
    mediaQueries.addEventListener('mobile',
                                  'match',
                                  function(media, event) {
//                                      console.log("??????????????? " + media + " ????????????????");

                                      /* Check for a media (m) argument both in query string and hash. */
                                      var tmp = {};
                                      var attr;
                                      for (attr in argsMap) { tmp[attr] = argsMap[attr]; }
                                      for (attr in hashArgsMap) { tmp[attr] = hashArgsMap[attr]; }
                                      if ((tmp.m == undefined)
                                          || ((tmp.m != undefined)
                                              && (tmp.m != 'desktop'))) {

//                                          console.log("!!!! REDIRECT !!!!");
                                          var mobileUrl = urlUtils.desktop2mobile(window.location.href);
                                          window.location.replace(mobileUrl);
                                      }
                                  });
    mediaQueries.trigger(['match']);
//    /* WARNING : Remove the following line for mobile support */
//    body.width(appWidth);

    /* Get the application container. */
    var appContainer = $('#fgapp_container');
    appState.width = appContainer.width();
    
    body.addClass(appSettings.design + '_main_container');
    /* Set the body width. */
    body.width(appWidth);

    /* Check if canvas is supported by the browser. */
    if (! Modernizr.canvas) {
        alert('Some features used to display graphics are currently not supported by your browser. Please upgrade to a more recent version or another browser. Many thanks.');
        return;
    }

    /* Start the updater. */
    theUpdater.start();

    /* Initialize the i18next used for translation and namespaces. */
    i18n.init({lng                 : 'en',
               fallbackLng         : '',
               resGetPath          : appSettings.getUrlLocalesPath() + '/__lng__/__ns__.json?v=1424272494',
               ns                  : {namespaces : ['standard', 'demo', 'oanda', 'advfn', 'fab', 'vfortes', 'mtb', 'jmi', 'tradersg', 'traderse', 'ib', 'dct', 'market', 'mrkthomepage', 'agefi', 'crypto', 'moneymuseum', 'filters'],
                                      defaultNS  : 'standard'},
               fallbackToDefaultNS : true,
               fallbackNS          : ['standard', 'demo', 'oanda', 'advfn', 'fab', 'vfortes', 'mtb', 'jmi', 'tradersg', 'traderse', 'ib', 'dct', 'market', 'mrkthomepage', 'agefi', 'crypto', 'moneymuseum'  ],
               getAsync            : false,
               debug               : false });

    
    i18n.setDefaultNamespace(appSettings.site);

    /* Initialize the message overlay. */
    jsoverlay.initModule(appContainer);

    /* Initialize the FinHTML class. */
    mjtHTML.FinHTML = new mjtHTML.MJTHTML();

    /* Initialize the FinTabs class.
       userAdmin depends on it because it use the RD (manageuser) code. */
    mjtTabs.FinTabs = new mjtTabs.MJTTABS();

    /* Set the requests queue to the oldUtils module.
       This need to be removed when oldUtils will be removed. */
    oldUtils.setReqQueue(requestsQueue);
    
    /* Initialize the shell. */
    shell.initModule(appContainer);
    shell.configModule({updater       : theUpdater,
                        requestsQueue : requestsQueue});
    shell.onReady();
    shell.showContent(location.hash.slice(1),
                      argsMap);

}

/* -------------------------------------------------------------------------- */

function onPageUnload() {
    /**
     * Called when the page is unloaded.
     */

    //console.log("onPageUnload()");

    theUpdater.setAllViewContextsInactive();
}

/* -------------------------------------------------------------------------- */

function onHashChanged() {
    /**
     * Called when the location has changed.
     */

    //console.log("onHashChanged()");

    navigation.pullState(location.hash);
}

/* -------------------------------------------------------------------------- */

//
//main.js ends here
